import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { MessageCircle, X, Send, Bot } from 'lucide-react';

const FloatingChat = ({ token }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const toggleChat = () => {
    setIsOpen(!isOpen);
    if (!isOpen && messages.length === 0) {
      setMessages([{ text: '¡Hola! Soy Mark tu asistente personal, estoy aquí para ayudarte con tus preguntas sobre marketing. ¿En qué puedo ayudarte hoy?', sender: 'bot' }]);
    }
  };

  const handleInputChange = (e) => {
    setInputMessage(e.target.value);
  };

  const sendMessage = async () => {
    if (inputMessage.trim() === '') return;
    if (!token) {
      setMessages([...messages, { text: 'Por favor, inicia sesión para usar el chat.', sender: 'bot' }]);
      return;
    }

    const newMessage = { text: inputMessage, sender: 'user' };
    setMessages([...messages, newMessage]);
    setInputMessage('');
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/chat`, 
        { message: inputMessage },
        { 
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          } 
        }
      );
      
      const botMessage = { text: response.data.reply, sender: 'bot' };
      setMessages(messages => [...messages, botMessage]);
    } catch (error) {
      console.error('Error sending message:', error);
      const errorMessage = { text: 'Lo siento, hubo un error al procesar tu mensaje. Por favor, inténtalo de nuevo más tarde.', sender: 'bot' };
      setMessages(messages => [...messages, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {!isOpen && (
        <div className="relative">
          <button
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            onClick={toggleChat}
            className="bg-blue-600 hover:bg-blue-700 text-white rounded-full p-4 shadow-lg transition duration-300 flex items-center justify-center"
          >
            <MessageCircle size={28} />
          </button>
          {showTooltip && (
            <div className="absolute bottom-full right-0 mb-2 bg-gray-800 text-white rounded-lg p-2 shadow-lg text-sm whitespace-nowrap">
              ¡Hola! Soy Mark, tu asistente. ¿Necesitas ayuda?
            </div>
          )}
        </div>
      )}
      {isOpen && (
        <div className="bg-gray-900 rounded-lg shadow-2xl w-96 h-[32rem] flex flex-col overflow-hidden">
          <div className="bg-gray-800 text-white p-4 flex justify-between items-center">
            <div className="flex items-center">
              <Bot size={24} className="mr-2 text-blue-400" />
              <h3 className="font-semibold text-lg">Asistente Mark</h3>
            </div>
            <button onClick={toggleChat} className="text-gray-400 hover:text-white transition duration-150">
              <X size={24} />
            </button>
          </div>
          <div className="flex-grow overflow-y-auto p-4 bg-gray-800">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`mb-4 ${
                  message.sender === 'user' ? 'text-right' : 'text-left'
                }`}
              >
                <span
                  className={`inline-block p-3 rounded-lg max-w-[80%] ${
                    message.sender === 'user'
                      ? 'bg-blue-600 text-white'
                      : 'bg-gray-700 text-white'
                  }`}
                >
                  {message.text}
                </span>
              </div>
            ))}
            {isLoading && (
              <div className="text-center">
                <span className="inline-block p-2 rounded-lg bg-gray-700 text-white animate-pulse">
                  Escribiendo...
                </span>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
          <div className="p-4 bg-gray-800 border-t border-gray-700">
            <div className="flex items-center">
              <input
                type="text"
                value={inputMessage}
                onChange={handleInputChange}
                placeholder="Escribe tu mensaje..."
                className="flex-grow bg-gray-700 text-white border-gray-600 rounded-l-lg p-3 focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-gray-400"
                onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
              />
              <button
                onClick={sendMessage}
                className="bg-blue-600 text-white rounded-r-lg p-3 hover:bg-blue-700 transition duration-300"
              >
                <Send size={20} />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FloatingChat;