import React, { useState, useEffect } from 'react';

const MarketingPlanGenerator = ({ generatingPlan, initialProgress = 0 }) => {
  const [progress, setProgress] = useState(initialProgress);

  useEffect(() => {
    if (generatingPlan && progress < 100) {
      const timer = setInterval(() => {
        setProgress((prevProgress) => {
          const increment = Math.random() * 0.5 + 0.1; // Incremento aleatorio entre 0.1 y 0.6
          const newProgress = prevProgress + increment;
          return newProgress > 100 ? 100 : newProgress;
        });
      }, 300); // Aumentado el intervalo a 200ms
      return () => clearInterval(timer);
    }
  }, [generatingPlan, progress]);

  if (!generatingPlan) return null;

  return (
    <div className="max-w-md mx-auto mt-8 p-6 bg-gray-800 rounded-lg shadow-xl border border-gray-700">
      <h2 className="text-2xl font-bold mb-4 text-purple-300">Generando Plan de Marketing</h2>
      <div className="mb-4">
        <div className="flex justify-between mb-1">
          <span className="text-base font-medium text-purple-300">{Math.floor(progress)}% Completado</span>
        </div>
        <div className="w-full bg-gray-700 rounded-full h-2.5">
          <div 
            className="bg-purple-500 h-2.5 rounded-full transition-all duration-700 ease-out"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      </div>
      <p className="text-sm text-gray-400 animate-pulse">
        Analizando datos y optimizando estrategias...
      </p>
    </div>
  );
};

export default MarketingPlanGenerator;