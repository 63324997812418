import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import IntroComponent from './components/IntroComponent/IntroComponent';
import WelcomeScreen from './components/UI/welcomescreen';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import LoginForm from './components/Login/LoginForm';
import PasswordResetRequestForm from './components/Login/PasswordResetRequestForm';
import UserStats from './components/UserStats';
import PasswordResetForm from './components/Login/PasswordResetForm';
import RegisterForm from './components/Register/RegisterForm';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles.css';
import Sidebar from './components/UI/sidebar';
import ResponseHistory from './components/ResponseHistory/ResponseHistory';
import LoadingSpinner from './components/UI/LoadingSpinner';
import ErrorMessage from './components/UI/ErrorMessage';
import Calendar from './components/Calendar/calendar';
import { gapi } from 'gapi-script';
import LinkedInComponent from './components/Linkedin/LinkedinInComponent';
import { motion, AnimatePresence } from 'framer-motion';
import FloatingChat from './components/FloatingChat';
import { ChevronRight, Users, Target, Star } from 'lucide-react';
import MarketingPlanGenerator from './components/marketingplangenerator';


const App = () => {
  const [sections, setSections] = useState({});
  const [mainInterests, setMainInterests] = useState([]);
  const [subInterests, setSubInterests] = useState({});
  const [userStats, setUserStats] = useState(null);
  const [loading, setLoading] = useState(true);


  const [responses, setResponses] = useState({});
  const [currentSection, setCurrentSection] = useState('');
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedEvents, setSelectedEvents] = useState([]);  // Asegúrate de definirlo
  const [sessionId, setSessionId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [normalizedEvents, setNormalizedEvents] = useState([]);
  const [showWelcome, setShowWelcome] = useState(false);
  const [showIntro, setShowIntro] = useState(true);
  const [error, setError] = useState(null);
  const [rawMarketingPlan, setRawMarketingPlan] = useState(null);
  const [parsedPosts, setParsedPosts] = useState([]);
  const [selectedMainInterests, setSelectedMainInterests] = useState([]);
  const [selectedSubInterests, setSelectedSubInterests] = useState({});
  const [customAnswer, setCustomAnswer] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState('');
  const [showRegister, setShowRegister] = useState(false);
  const [savedResponses, setSavedResponses] = useState(null);
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [savedPlans, setSavedPlans] = useState([]);
  const [isGoogleApiReady, setIsGoogleApiReady] = useState(false);
  const [googleUser, setGoogleUser] = useState(null);
  const [showAuthOptions, setShowAuthOptions] = useState(false);
  const [generatingPlan, setGeneratingPlan] = useState(false);
  const [generationProgress, setGenerationProgress] = useState(0);
  const [marketingPlan, setMarketingPlan] = useState([]);
  const [showPasswordResetRequest, setShowPasswordResetRequest] = useState(false);
  const [showPasswordResetForm, setShowPasswordResetForm] = useState(false);
  const [passwordResetToken, setPasswordResetToken] = useState('');
// En App.js
const [rawResponses, setRawResponses] = useState(null);

  const [passwordResetEmail, setPasswordResetEmail] = useState('');
  const [calendarEvents, setCalendarEvents] = useState([]);

  const [showLogin, setShowLogin] = useState(false);
  const [isGeneratingPlan, setIsGeneratingPlan] = useState(false);
  // Estado para controlar la visualización de UserStats
  const [showUserStats, setShowUserStats] = useState(false);
  const longPressTimerRef = useRef(null);
  const [isLongPress, setIsLongPress] = useState(false);

  // Funciones para manejar la pulsación larga
  const handleLongPressStart = () => {
    setIsLongPress(false);
    longPressTimerRef.current = setTimeout(() => {
      setIsLongPress(true);
      setShowUserStats(true);
    }, 15000); // 15000 ms = 15 segundos
  };

  const handleLongPressEnd = () => {
    if (longPressTimerRef.current) {
      clearTimeout(longPressTimerRef.current);
      longPressTimerRef.current = null;
    }
  };



  useEffect(() => {
    if (isLoggedIn) {
      fetchSavedResponses();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const fetchUserStats = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/user-stats`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }
        );
        setUserStats(response.data);
        setError(null);
      } catch (err) {
        setError(
          err.response?.data?.message || 
          'Error al obtener las estadísticas de usuarios'
        );
      } finally {
        setLoading(false);
      }
    };
    fetchUserStats();
  }, []);


  const questionRefs = useRef({});
  const inputRefs = useRef({});
  const containerRef = useRef(null);

  const predefinedOptions = {
    "¿Cómo te definirías profesionalmente?": [
      "Soy un profesional dedicado al desarrollo de estrategias efectivas y sostenibles",
      "Me considero un experto en la optimización y mejora continua de procesos",
      "Soy un líder enfocado en la innovación y la resolución de problemas",
      "Me defino como un especialista en el diseño e implementación de soluciones estratégicas",
      "Soy un profesional comprometido con el crecimiento y la transformación organizacional",
      "Me considero un experto en la gestión y desarrollo de proyectos de alto impacto",
      "Soy un estratega enfocado en la creación de valor a largo plazo"
    ],
    "¿Cuánto tiempo piensas que podrías invertir en este proyecto?": [
      "Menos de 1 hora por semana",
      "Hasta 1 hora por semana",
      "Hasta 3 horas por semana",
      "Hasta 5 horas por semana",
      "Todas las horas que sean necesarias"
    ]
  };

 
  const handleError = useCallback((error, customMessage) => {
    console.error(customMessage, error);
    setError(customMessage);
    setIsLoading(false);
  }, []);


  useEffect(() => {
    const hasSeenIntro = localStorage.getItem('hasSeenIntro');
    if (!hasSeenIntro) {
      setShowIntro(true);
    } else {
      setShowIntro(false);
      setCurrentSection('Entrevista');
      setCurrentQuestionIndex(0);
    }
  }, []);


  useEffect(() => {
    const checkLoginStatus = async () => {
      const storedToken = localStorage.getItem('token');
      if (storedToken) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/verify-token`, {
            headers: { Authorization: `Bearer ${storedToken}` },
            timeout: 5000
          });
          if (response.data.valid) {
            setToken(storedToken);
            setIsLoggedIn(true);
            setUsername(localStorage.getItem('username'));
            setFirstName(localStorage.getItem('firstName')); // Recupera el nombre de localStorage
            await fetchSavedResponses(storedToken);
          } else {
            throw new Error('Token inválido');
          }
        } catch (error) {
          console.error('Error de autenticación:', error);
          handleLogout();
        }
      }
      setIsAuthChecked(true);
      setIsLoading(false);
    };
  
    checkLoginStatus();
  }, []);


  useEffect(() => {
    if (isLoggedIn) {
      fetchSections();
    }
  }, [isLoggedIn, token]);

  useEffect(() => {
    const loadGoogleApi = async () => {
      const gapi = await import('gapi-script').then((pack) => pack.gapi);
      gapi.load('client:auth2', initClient);
    };

    loadGoogleApi();
  }, []);

  const initClient = () => {
    window.gapi.client.init({
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
      scope: "https://www.googleapis.com/auth/calendar.events"
    }).then(() => {
      console.log("Google Calendar API inicializada correctamente");
      setIsGoogleApiReady(true);
    }).catch(error => {
      console.error("Error al inicializar Google Calendar API:", error);
    });
  };



  useEffect(() => {
    const savedEvents = localStorage.getItem('calendarEvents');
    if (savedEvents) {
      setCalendarEvents(JSON.parse(savedEvents)); // Carga los eventos guardados en localStorage
    }
  }, []);
  
  useEffect(() => {
    if (calendarEvents.length > 0) {
      localStorage.setItem('calendarEvents', JSON.stringify(calendarEvents)); // Guarda los eventos en localStorage
    }
  }, [calendarEvents]);
  
 
// Simular el estado de autenticación para cargar eventos
useEffect(() => {
  if (isLoggedIn) {
    fetchCalendarEvents();  // Cargar los eventos cuando el usuario está autenticado
  }
}, [isLoggedIn, token]);



// Modifica la función fetchCalendarEvents en App.js
const fetchCalendarEvents = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/get-calendar-events`,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    if (response.data.events) {
      const formattedEvents = response.data.events.map(event => ({
        id: event.id,
        title: event.title,
        start: new Date(event.start),
        end: new Date(event.end),
        extendedProps: {
          content: event.content,
          hashtags: event.hashtags,
          platform: event.platform || 'LinkedIn',
          image: event.image,
          status: event.status || 'pending'
        }
      }));

      // Crear un mapa con los IDs existentes
      const existingEventsMap = new Map(
        calendarEvents.map(event => [event.id, event])
      );

      // Combinar eventos existentes con los nuevos
      formattedEvents.forEach(event => {
        existingEventsMap.set(event.id, event);
      });

      // Convertir el mapa de vuelta a array
      const updatedEvents = Array.from(existingEventsMap.values());
      
      setCalendarEvents(updatedEvents);
      setEvents(updatedEvents);
    }
  } catch (error) {
    console.error('Error fetching calendar events:', error);
    setError('Error al cargar los eventos del calendario');
  }
};

// Modifica la función handleEventUpdate
const handleEventUpdate = useCallback(async (updatedEvents) => {
  try {
    const formattedEvents = Array.isArray(updatedEvents) 
      ? updatedEvents 
      : [updatedEvents];

    // Actualizar estado local primero
    setCalendarEvents(prevEvents => {
      const eventMap = new Map(prevEvents.map(e => [e.id, e]));
      formattedEvents.forEach(event => {
        eventMap.set(event.id, event);
      });
      return Array.from(eventMap.values());
    });

    // Enviar actualización al servidor
    await axios.post(
      `${process.env.REACT_APP_API_URL}/api/save-calendar`,
      { events: formattedEvents },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    // Recargar eventos para asegurar sincronización
    await fetchCalendarEvents();
  } catch (error) {
    console.error('Error updating events:', error);
    setError('Error al actualizar los eventos. Por favor, inténtelo de nuevo.');
    // Recargar eventos en caso de error
    await fetchCalendarEvents();
  }
}, [token, fetchCalendarEvents]);
  

  const handleGoogleLoginSuccess = async (credentialResponse) => {
    try {
      const { credential } = credentialResponse;
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/verify-google-token`, {
        token: credential
      });
  
      if (response.data.valid) {
        setGoogleUser(response.data.user);
        localStorage.setItem('googleToken', credential);
        await initGoogleCalendarClient();
        await fetchGoogleCalendarEvents();
        setError(null);
        console.log('Inicio de sesión con Google exitoso');
      } else {
        throw new Error('Token de Google inválido');
      }
    } catch (error) {
      console.error('Error al verificar el token de Google:', error);
      setError('Error al iniciar sesión con Google. Por favor, inténtalo de nuevo.');
      setGoogleUser(null);
    }
  };
  


  // Manejo de actualización de eventos en el calendario
const onEventChange = async (updatedEvents) => {
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/api/update-marketing-plan`, {
      plan: updatedEvents,
      plan_index: savedPlans.length - 1 // Guardamos en el último plan
    }, {
      headers: { Authorization: `Bearer ${token}` }
    });
    console.log('Calendario guardado exitosamente');
  } catch (error) {
    console.error('Error al guardar el calendario:', error);
  }
};
  const initGoogleCalendarClient = async () => {
    if (!window.gapi) {
      console.error('Google API client no está cargado');
      return;
    }
  
    try {
      await window.gapi.client.init({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
        scope: "https://www.googleapis.com/auth/calendar.events"
      });
  
      console.log('Google Calendar API inicializada correctamente');
      setIsGoogleApiReady(true);
    } catch (error) {
      console.error('Error al inicializar Google Calendar API:', error);
      setError('Error al inicializar Google Calendar. Por favor, recarga la página e inténtalo de nuevo.');
    }
  };
  
  const fetchGoogleCalendarEvents = async () => {
    if (!isGoogleApiReady) {
      console.error('Google Calendar API no está lista');
      setError('La API de Google Calendar no está inicializada. Por favor, recarga la página.');
      return;
    }
  
    if (!googleUser) {
      console.error('Usuario no autenticado en Google');
      setError('Por favor, inicia sesión con Google para acceder al calendario.');
      return;
    }
  
    setIsLoading(true);
  
    try {
      const response = await window.gapi.client.calendar.events.list({
        'calendarId': 'primary',
        'timeMin': (new Date()).toISOString(),
        'showDeleted': false,
        'singleEvents': true,
        'maxResults': 10,
        'orderBy': 'startTime'
      });
  
      const events = response.result.items;
      console.log('Eventos del calendario obtenidos:', events);
  
      const formattedEvents = events.map(event => ({
        id: event.id,
        title: event.summary,
        start: event.start.dateTime || event.start.date,
        end: event.end.dateTime || event.end.date,
        description: event.description,
        location: event.location,
        htmlLink: event.htmlLink,
      }));
  
      setCalendarEvents(formattedEvents);
  
      setError(null);
      console.log('Eventos del calendario actualizados en el estado');
    } catch (error) {
      console.error('Error al obtener eventos del calendario:', error);
      
      let errorMessage = 'Error al obtener eventos del calendario. ';
      
      if (error.result && error.result.error) {
        errorMessage += `${error.result.error.message} `;
      }
      
      errorMessage += 'Por favor, inténtalo de nuevo o verifica los permisos de tu cuenta.';
      
      setError(errorMessage);
      setCalendarEvents([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleLoginFailure = () => {
    console.error('Google login failed');
  };


  const fetchSections = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/start-session`, {}, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 10000
      });
      
      setSections(response.data.sections);
      setMainInterests(response.data.main_interests);
      setSessionId(response.data.session_id);
      
      const firstSection = Object.keys(response.data.sections)[0];
      setCurrentSection(firstSection);
      
      initializeResponses(response.data.sections);
  
      // Limpiar el mensaje de error si la operación fue exitosa
      setError(null);
  
      setIsLoading(false);
    } catch (error) {
      handleError(error, 'Error al cargar las secciones. Por favor, intenta de nuevo.');
    }
  };
  
  const initializeResponses = (sections) => {
    const initialResponses = savedResponses || {};
    Object.keys(sections).forEach(section => {
      questionRefs.current[section] = sections[section].map(() => React.createRef());
      inputRefs.current[section] = sections[section].map(() => React.createRef());
      if (!initialResponses[section]) {
        initialResponses[section] = {};
        sections[section].forEach(question => {
          initialResponses[section][question] = { value: '' };
        });
      }
    });
    setResponses(initialResponses);
  };


  useEffect(() => {
    if (
      questionRefs.current[currentSection] &&
      questionRefs.current[currentSection][currentQuestionIndex] &&
      questionRefs.current[currentSection][currentQuestionIndex].current
    ) {
      questionRefs.current[currentSection][currentQuestionIndex].current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [currentQuestionIndex, currentSection]);
  

   // Modifica la función handleLogin
   const handleLogin = async (usernameOrEmail, password) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/login`, {
        username_or_email: usernameOrEmail,
        password
      });
      const { access_token, username, first_name, last_name } = response.data;
  
      setToken(access_token);
      setIsLoggedIn(true);
      setUsername(username);
      setFirstName(first_name);
      setLastName(last_name);
  
      localStorage.setItem('token', access_token);
      localStorage.setItem('username', username);
      localStorage.setItem('firstName', first_name);
      localStorage.setItem('lastName', last_name);
  
      setShowWelcome(true);
      setError(null);
      await fetchSavedResponses(access_token);
    } catch (error) {
      handleError(error, 'Error de inicio de sesión. Verifica tus credenciales.');
    }
  };

  const handleRegister = async (email, password, firstName, lastName) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/register`, {
        email,
        password,
        first_name: firstName,
        last_name: lastName
      });
  
      if (response.status === 201) {
        alert(response.data.message || 'Registro exitoso. Ahora puedes iniciar sesión.');
        setError('');
        setShowRegister(false);
        setShowLogin(true);
      } else {
        setError('Error en el registro. Inténtalo de nuevo.');
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError('Error en el registro. Inténtalo de nuevo.');
      }
    }
  };
  
  const handleEventSelect = (event) => {
    // Si tienes un estado para eventos seleccionados, actualízalo aquí
    setSelectedEvent(event);
  
    // También podrías querer realizar otras acciones cuando se selecciona un evento
    console.log('Evento seleccionado:', event);
  };

  const handleLogout = () => {
    setToken('');
    setIsLoggedIn(false);
    setUsername('');
    setFirstName(''); // Limpia el nombre
    setSavedResponses(null);
    setResponses({});
    setCurrentSection('');
    setCurrentQuestionIndex(0);
    setShowIntro(true);
    setIsAuthChecked(true);
    setIsLoading(false);
    setGoogleUser(null);
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('username');
    localStorage.removeItem('firstName'); // Elimina el nombre de localStorage
  };


  const fetchSavedResponses = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-responses`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setResponses(response.data); // Eliminar la mezcla con hardcodedResponses
    } catch (error) {
      console.error('Failed to fetch saved responses:', error);
    }
  };
  



useEffect(() => {
  if (isLoggedIn) {
    fetchSavedResponses();
  }
}, [isLoggedIn]);




const saveResponses = async () => {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/api/save-responses`, 
      { responses }, 
      { headers: { Authorization: `Bearer ${token}` } }
    );
    console.log('Respuestas guardadas exitosamente:', responses);
  } catch (error) {
    console.error('Error al guardar las respuestas:', error);
    setError('Error al guardar las respuestas. Por favor, intenta de nuevo.');
  }
};
const handleAnswerChange = (question, value) => {
  setResponses(prevResponses => ({
    ...prevResponses,
    [currentSection]: {
      ...prevResponses[currentSection],
      [question]: { value }
    }
  }));
};





// Modifica la función handleStartJourney
const handleStartJourney = () => {
  setShowWelcome(false);
  setShowIntro(false);
  setCurrentSection('Entrevista');
  setCurrentQuestionIndex(0);
  localStorage.setItem('hasSeenIntro', 'true');
};



  const handleEditResponses = () => {
    setCurrentSection('Entrevista');
    setCurrentQuestionIndex(0);
    setShowHistory(false);
    setShowIntro(false);
  };


  const handleSavePlan = async () => {
    if (!rawMarketingPlan) {
      setError('No hay un plan de marketing para guardar.');
      return;
    }
  
    try {
      const planData = {
        plan: rawMarketingPlan,
        parsed_posts: parsedPosts,
      };
  
      await axios.post(`${process.env.REACT_APP_API_URL}/api/save-marketing-plan`, planData, {
        headers: { Authorization: `Bearer ${token}` }
      });
  
      alert('Plan de marketing guardado con éxito');
    } catch (error) {
      handleError(error, 'Error al guardar el plan de marketing. Inténtalo de nuevo.');
    }
  };
  
  
  const handleCustomAnswerChange = (e) => {
    setCustomAnswer(e.target.value);
  };

  const handleMainInterestChange = async (interest) => {
    setSelectedMainInterests(prev => {
      const newInterests = prev.includes(interest)
        ? prev.filter(i => i !== interest)
        : [...prev, interest].slice(0, 3);
      
      setSelectedSubInterests(prevSub => {
        const newSub = {...prevSub};
        if (!newInterests.includes(interest)) {
          delete newSub[interest];
        }
        return newSub;
      });

      return newInterests;
    });

    if (!subInterests[interest]) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/get-sub-interests`, { main_interest: interest });
        setSubInterests(prev => ({ ...prev, [interest]: response.data.sub_interests }));
      } catch (error) {
        handleError(error, 'Error al cargar los sub-intereses. Por favor, intenta de nuevo.');
      }
    }
  };


  // Modifica la función handleForgotPassword
const handleForgotPassword = () => {
  console.log('handleForgotPassword called'); // Debug log
  setShowPasswordResetRequest(true);
  setShowRegister(false);
  setShowLogin(false);
};



// Añade la función para manejar la solicitud de restablecimiento
const handleRequestReset = async (email) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/request-password-reset`, { email });
    setPasswordResetEmail(email);
    setShowPasswordResetRequest(false);
    setShowPasswordResetForm(true);
    alert(response.data.message);
  } catch (error) {
    setError(error.response?.data?.message || 'Error al solicitar el restablecimiento de contraseña');
  }
};

// Añade la función para manejar el restablecimiento de contraseña
const handleResetPassword = async (email, code, newPassword) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/reset-password`, {
      email,
      code,
      new_password: newPassword
    });
    alert(response.data.message);
    setShowPasswordResetForm(false);
    setShowLogin(true);
  } catch (error) {
    setError(error.response?.data?.message || 'Error al restablecer la contraseña');
  }
};
  
  const onRequestReset = async (email) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/request-password-reset`, { email });
      alert(response.data.message);
      setShowPasswordResetRequest(false);
      // Establece el correo electrónico y muestra el formulario de restablecimiento
      setPasswordResetEmail(email);
      setShowPasswordResetForm(true);
    } catch (error) {
      console.error('Error al solicitar restablecimiento:', error);
      setError('Error al solicitar restablecimiento de contraseña. Por favor, intenta de nuevo.');
    }
  };
  
  
  const onResetPassword = async (email, code, newPassword) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/reset-password`, { email, code, new_password: newPassword });
      alert(response.data.message);
      setShowPasswordResetForm(false);
    } catch (error) {
      console.error('Error al restablecer contraseña:', error);
      setError('Error al restablecer la contraseña. Por favor, intenta de nuevo.');
    }
  };
  





  const handleSubInterestChange = (mainInterest, subInterest) => {
    setSelectedSubInterests(prev => ({
      ...prev,
      [mainInterest]: prev[mainInterest]
        ? prev[mainInterest].includes(subInterest)
          ? prev[mainInterest].filter(i => i !== subInterest)
          : [...prev[mainInterest], subInterest]
        : [subInterest]
    }));
  };

  const updateInterestsInBackend = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/update-interests`, {
        session_id: sessionId,
        main_interests: selectedMainInterests,
        sub_interests: selectedSubInterests
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
    } catch (error) {
      handleError(error, 'Error al actualizar los intereses. Por favor, intenta de nuevo.');
    }

  
  };


  const fetchMarketingPlan = async () => {
    try {
      setError(null);
      setGeneratingPlan(true);
      setGenerationProgress(0);
  
      // Validación inicial...
      if (!responses) {
        throw new Error('No hay respuestas disponibles');
      }
  
      // Primero, realizar el análisis de personalidad si aún no existe
      if (!responses.personality_analysis_ai) {
        setGenerationProgress(10);
        try {
          const personalityResponse = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/analyze-personality-ai`,
            { responses },
            { headers: { Authorization: `Bearer ${token}` } }
          );
  
          setResponses((prevResponses) => ({
            ...prevResponses,
            personality_analysis_ai: personalityResponse.data.personality_analysis,
          }));
        } catch (error) {
          console.error('Error al analizar personalidad:', error);
          throw new Error('Error al analizar el test de personalidad');
        }
      }
  
      setGenerationProgress(20);
  
      // Verificar que exista la sección de Preferencias de Marketing
      const marketingPrefs = responses['Preferencias de Marketing'];
      if (!marketingPrefs) {
        throw new Error('Por favor, completa la sección de Preferencias de Marketing');
      }
  
      // Obtener duración y frecuencia con validación
      let planDuration = 'semanal';
      let postFrequency = 'diario';
  
      for (const [question, answer] of Object.entries(marketingPrefs)) {
        if (question.includes('duración') && answer?.value) {
          planDuration = answer.value.toLowerCase();
        }
        if (question.includes('frecuencia') && answer?.value) {
          postFrequency = answer.value.toLowerCase();
        }
      }
  
      if (!planDuration || !postFrequency) {
        throw new Error(
          'Por favor, completa la duración y frecuencia del plan en las Preferencias de Marketing'
        );
      }
  
      setGenerationProgress(30);
  
      // Calcular días a generar
      const daysToGenerate = getDaysToGenerate(planDuration);
      const frequencyDays = getFrequencyDays(postFrequency);
  
      if (!daysToGenerate || !frequencyDays) {
        throw new Error('Valores de duración o frecuencia no válidos');
      }
  
      let generatedPosts = [];
      const totalPosts = Math.ceil(daysToGenerate / frequencyDays);
  
      // Generar posts
      for (let day = 1; day <= daysToGenerate; day += frequencyDays) {
        if (postFrequency === 'cada 2 días' && generatedPosts.length >= 3) break;
  
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/generate-daily-plan`,
            {
              day_number: day,
              user_data: responses,
              duration: planDuration,
              frequency: postFrequency,
              personality_analysis: responses.personality_analysis_ai,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          );
  
          if (response.data.success) {
            generatedPosts.push(response.data.post);
            const progress = 30 + (generatedPosts.length / totalPosts) * 50;
            setGenerationProgress(Math.min(80, progress));
          } else {
            throw new Error('Error en la generación del post');
          }
        } catch (error) {
          console.error('Error generando post para el día', day, error);
          throw new Error(`Error generando el post para el día ${day}`);
        }
      }
  
      setGenerationProgress(90);
  
      if (generatedPosts.length === 0) {
        throw new Error('No se pudieron generar posts');
      }
  
      const newCalendarEvents = generatedPosts.map((post, index) => ({
        id: post.id || `post-${index}`,
        title: post.title || 'Post sin título',
        start: new Date(post.date),
        end: new Date(new Date(post.date).getTime() + 60 * 60 * 1000),
        extendedProps: {
          content: post.content || '',
          platform: post.platform || 'LinkedIn',
          hashtags: post.hashtags || '',
          image: post.image || null,
          status: 'pending'
        }
      }));
  
      // Guardar el plan
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/save-marketing-plan`,
          {
            plan: generatedPosts,
            calendar_events: newCalendarEvents,
            personality_analysis: responses.personality_analysis_ai,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
      } catch (error) {
        console.error('Error guardando el plan:', error);
        throw new Error('Error al guardar el plan de marketing');
      }
  
      setGenerationProgress(100);
  
      // Actualizar estados
      setParsedPosts(generatedPosts);
  
      // Combinar los eventos del calendario sin duplicados
      setCalendarEvents((prevEvents) => {
        const eventsMap = new Map();
  
        const combinedEvents = [...prevEvents, ...newCalendarEvents];
  
        combinedEvents.forEach((event) => {
          eventsMap.set(event.id, event); // Evita duplicados usando el ID del evento
        });
  
        return Array.from(eventsMap.values());
      });
  
      setRawMarketingPlan(JSON.stringify(generatedPosts, null, 2));
      setShowIntro(false);
      setShowWelcome(false);
  
      setTimeout(() => {
        setGeneratingPlan(false);
        setGenerationProgress(0);
      }, 500);
    } catch (error) {
      console.error('Error en la generación del plan:', error);
      setError(error.message || 'Error al generar el plan de marketing. Por favor, inténtalo de nuevo.');
      setGeneratingPlan(false);
      setGenerationProgress(0);
    }
  };
  

  const getFrequencyDays = (frequency) => {
    switch (frequency.toLowerCase()) {
      case 'diario':
        return 1;
      case 'cada 2 días':
        return 2;
      case 'semanal':
        return 7;
      default:
        return 1; // Por defecto, diario
    }
  };


  const getDaysToGenerate = (planDuration) => {
    switch (planDuration.toLowerCase()) {
      case 'semanal':
        return 7;
      case 'mensual':
        return 30;
      case 'trimestral':
        return 90;
      default:
        return 7; // Por defecto, semanal
    }
  };

  const handleNextSection = async () => {
    try {
      if (currentSection === "Intereses") {
        await updateInterestsInBackend();
      }
  
      // Si estamos terminando el Test de Personalidad
      if (currentSection === "Test de Personalidad" && 
          currentQuestionIndex === sections[currentSection].length - 1) {
        try {
          setIsLoading(true);
          console.log("Analizando personalidad...");  // Para debug
          
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/analyze-personality-ai`,
            { responses },
            { headers: { Authorization: `Bearer ${token}` } }
          );
  
          console.log("Respuesta análisis:", response.data);  // Para debug
  
          // Actualizar el estado con el análisis
          setResponses(prevResponses => ({
            ...prevResponses,
            personality_analysis_ai: response.data.personality_analysis
          }));
  
        } catch (error) {
          console.error("Error al analizar personalidad:", error);
          setError("Error al analizar el test de personalidad. Por favor, intente nuevamente.");
          return;
        } finally {
          setIsLoading(false);
        }
      }
  
      const sectionKeys = Object.keys(sections);
      const currentSectionIndex = sectionKeys.indexOf(currentSection);
  
      if (currentSectionIndex < sectionKeys.length - 1) {
        setCurrentSection(sectionKeys[currentSectionIndex + 1]);
        setCurrentQuestionIndex(0);
      } else {
        await saveResponses();
        await fetchMarketingPlan();
      }
    } catch (error) {
      console.error("Error en handleNextSection:", error);
      setError("Ocurrió un error. Por favor, intente nuevamente.");
    }
  };
  





  const createCalendarEvents = (plan) => {
    if (!Array.isArray(plan)) {
      console.error('El plan no es un array:', plan);
      setCalendarEvents([]);
      return;
    }
  
    const events = plan.map(post => ({
      id: post.id,
      title: post.title,
      start: new Date(post.date),
      end: new Date(new Date(post.date).getTime() + 60 * 60 * 1000), // 1 hora después
      extendedProps: {
        content: post.content,
        platform: post.platform,
      }
    }));
  
    setCalendarEvents(events);  // Actualiza los eventos del calendario
    setShowCalendar(true);
  };

  const fetchSavedPlans = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-marketing-plans`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      const marketingPlans = response.data?.marketing_plans || [];
      setSavedPlans(marketingPlans);
  
      if (marketingPlans.length > 0) {
        const latestPlan = marketingPlans[marketingPlans.length - 1];
        const planEvents = latestPlan.plan || [];
  
        setCalendarEvents((prevEvents) => {
          const eventsMap = new Map();
  
          const combinedEvents = [...prevEvents, ...planEvents];
  
          combinedEvents.forEach((event) => {
            eventsMap.set(event.id, {
              ...event,
              start: new Date(event.start),
              end: new Date(event.end),
              extendedProps: {
                content: event.content,
                hashtags: event.hashtags,
                platform: event.platform,
                image: event.image,
              },
            });
          });
  
          return Array.from(eventsMap.values());
        });
      } else {
        console.log('No saved plans available. This is normal for new users.');
        // No sobrescribir calendarEvents, ya que podrías tener eventos existentes
      }
    } catch (error) {
      console.error('Error fetching marketing plans:', error);
      // No establecemos ningún error aquí, solo registramos en la consola
    }
  };
  
  

 

useEffect(() => {
  if (isLoggedIn) {
    fetchSavedResponses();
    fetchSavedPlans();
  }
}, [isLoggedIn]);


const handleSaveCalendar = async () => {
  try {
    const eventsToSave = selectedEvents.length > 0 ? selectedEvents : calendarEvents;

    if (eventsToSave.length === 0) {
      alert('No hay eventos para guardar.');
      return;
    }

    // Función auxiliar para extraer las propiedades extendidas
    const extractEventProps = (event) => {
      if (event._def) {
        return {
          content: event._def.extendedProps.content || '',
          hashtags: event._def.extendedProps.hashtags || '',
          platform: event._def.extendedProps.platform || 'LinkedIn',
          image: event._def.extendedProps.image || null,
        };
      }
      return {
        content: event.extendedProps?.content || '',
        hashtags: event.extendedProps?.hashtags || '',
        platform: event.extendedProps?.platform || 'LinkedIn',
        image: event.extendedProps?.image || null,
      };
    };

    const formattedEvents = eventsToSave.map((event) => {
      const eventProps = extractEventProps(event);
      const startDate = event._def ? event._instance.range.start : event.start;
      const endDate = event._def ? event._instance.range.end : event.end;

      return {
        id: event._def ? event._def.publicId : event.id,
        title: event._def ? event._def.title : event.title,
        start: new Date(startDate).toISOString(),
        end: new Date(endDate).toISOString(),
        content: eventProps.content,
        hashtags: eventProps.hashtags,
        platform: eventProps.platform,
        image: eventProps.image,
      };
    });

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/save-calendar`,
      { events: formattedEvents },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (response.status === 200) {
      alert('Calendario guardado exitosamente');

      // Actualizar el estado local combinando los eventos guardados
      const savedEvents = response.data.saved_events;
      setCalendarEvents((prevEvents) => {
        const eventsMap = new Map();

        const combinedEvents = [
          ...prevEvents,
          ...savedEvents.map((event) => ({
            ...event,
            start: new Date(event.start),
            end: new Date(event.end),
            extendedProps: {
              content: event.content,
              hashtags: event.hashtags,
              platform: event.platform,
              image: event.image,
            },
          })),
        ];

        combinedEvents.forEach((event) => {
          eventsMap.set(event.id, event);
        });

        return Array.from(eventsMap.values());
      });
    } else {
      throw new Error('Error al guardar el calendario');
    }
  } catch (error) {
    console.error('Error al guardar el calendario:', error);
    setError('Error al guardar el calendario. Por favor, intenta de nuevo.');
  }
};



const parseSinglePost = (postContent) => {
  const lines = postContent.split('\n');
  const post = {
    id: Date.now().toString(),
    type: 'unknown',
    extendedProps: {}
  };
  
  for (const line of lines) {
    if (line.startsWith('1. Fecha y hora:')) {
      const dateTime = line.split(': ')[1].trim();
      post.start = new Date(dateTime);
      post.end = new Date(post.start.getTime() + 60 * 60 * 1000);
    } else if (line.startsWith('2. Plataforma:')) {
      const platform = line.split(':')[1].trim().toLowerCase();
      post.extendedProps.platform = platform;
    } else if (line.startsWith('3. Título:')) {
      // Remove "Titulo:" from the title
      post.title = line.split(':').slice(1).join(':').trim();
    } else if (line.startsWith('4. Contenido:')) {
      post.extendedProps.content = line.split(':')[1].trim();
    } else if (line.startsWith('5. Hashtags:')) {
      post.extendedProps.hashtags = line.split(':')[1].trim();
    } else if (line.startsWith('6. Descripción de imagen/video:')) {
      post.extendedProps.mediaDescription = line.split(':')[1].trim();
    } else if (line.startsWith('7. Objetivo:')) {
      post.extendedProps.objective = line.split(':')[1].trim();
    }
  }
  
  return post;
};




  const handlePreviousSection = () => {
    const sectionKeys = Object.keys(sections);
    const currentSectionIndex = sectionKeys.indexOf(currentSection);
    if (currentSectionIndex > 0) {
      setCurrentSection(sectionKeys[currentSectionIndex - 1]);
      setCurrentQuestionIndex(sections[sectionKeys[currentSectionIndex - 1]].length - 1);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (currentQuestionIndex < sections[currentSection].length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        handleNextSection();
      }
    }
  };

 
  const handleGenerateNewPlan = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/generate-plan`, { responses }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setRawMarketingPlan(response.data.contenido);
      setParsedPosts(response.data.parsed_posts || []);
    } catch (error) {
      handleError(error, 'Error al generar el nuevo plan. Inténtalo de nuevo.');
    } finally {
      setIsLoading(false);
    }
  };
  
  

  const handleShowHistory = () => {
    setShowHistory(true);
    setShowCalendar(false);
  };
  const handleCreateCalendar = () => {
    setShowCalendar(true);
    // Removemos la comprobación de eventos y el mensaje de error
  };
  
  const parsePostsFromPlan = (plan) => {
    if (!plan || typeof plan !== 'string') {
      console.error('Invalid plan provided to parsePostsFromPlan');
      return [];
    }
  
    const posts = plan.split('--- POST INICIO ---')
      .filter(post => post.trim() !== '')
      .map(postContent => {
        const lines = postContent.split('\n');
        const post = {
          id: Date.now().toString() + Math.random().toString(36).substr(2, 9),
          title: '',
          start: null,
          end: null,
          extendedProps: {
            content: '',
            platform: '',
            hashtags: '',
            mediaDescription: '',
            objective: ''
          }
        };
  
        let isReadingContent = false;
        let currentSection = '';
  
        lines.forEach(line => {
          line = line.trim();
          if (line.startsWith('1. Fecha y hora:')) {
            const dateTimeStr = line.split(': ')[1].trim();
            const [datePart, timePart] = dateTimeStr.split(' ');
            const [day, month, year] = datePart.split('/');
            const [hour, minute] = timePart.split(':');
            post.start = new Date(year, month - 1, day, hour, minute);
            post.end = new Date(post.start.getTime() + 60 * 60 * 1000);
          } else if (line.startsWith('2. Plataforma:')) {
            post.extendedProps.platform = line.split(':')[1].trim();
          } else if (line.startsWith('3. Título:')) {
            post.title = line.split(':')[1].trim();
          } else if (line.startsWith('4. Contenido:')) {
            currentSection = 'content';
          } else if (line.startsWith('5. Hashtags:')) {
            currentSection = 'hashtags';
            post.extendedProps.hashtags = line.split(':')[1].trim();
          } else if (line.startsWith('6. Descripción de imagen/video:')) {
            currentSection = 'mediaDescription';
          } else if (line.startsWith('7. Objetivo:')) {
            currentSection = 'objective';
          } else if (line === '--- POST CUERPO ---' || line === '--- POST FIN ---') {
            // Ignorar estas líneas
          } else {
            // Agregar contenido a la sección actual
            if (currentSection) {
              post.extendedProps[currentSection] += line + '\n';
            }
          }
        });
  
        // Limpiar el contenido de cada sección
        Object.keys(post.extendedProps).forEach(key => {
          post.extendedProps[key] = post.extendedProps[key].trim();
        });
  
        console.log('Post parseado:', post); // Para debugging
  
        return post;
      });
  
    return posts;
  };
  const adjustDatesBasedOnFrequency = (posts, frequency) => {
    let startDate = new Date();
    return posts.map(post => ({
      id: post.id,
      title: post.title,
      start: post.start,
      end: post.end,
      extendedProps: {
        content: post.extendedProps.content,
        platform: post.extendedProps.platform,
        hashtags: post.extendedProps.hashtags,
        mediaDescription: post.extendedProps.mediaDescription,
        objective: post.extendedProps.objective
      }
    }));
  };
  
  

  const handleShowPlanCreation = (section) => {
    console.log('Cambiando a la sección:', section); // Log para depuración
    console.log('Estado actual:', { showHistory, showCalendar, currentSection });
  
    setShowHistory(false);
    setShowIntro(false);
  
    const sectionMapping = {
      'introduccion': 'Entrevista',
      'intereses': 'Intereses',
      'testPersonalidad': 'Test de Personalidad',
      'ultimosPosteos': 'Posteos Anteriores',
      'preferenciasMarketing': 'Preferencias de Marketing', 
      'crearCalendario': 'Crear Calendario',
      'verEditarCalendario': 'Ver/Editar Calendario',
    };
  
    const targetSection = sectionMapping[section];
  
    if (targetSection) {
      console.log('Sección objetivo:', targetSection); // Log para depuración
  
      setCurrentSection(targetSection);
      setCurrentQuestionIndex(0);
  
      if (rawMarketingPlan) {
        setRawMarketingPlan(null);
        setParsedPosts([]);
      }
  
      // Mostrar calendario solo si corresponde
      if (targetSection === 'Crear Calendario' || targetSection === 'Ver/Editar Calendario') {
        setShowCalendar(true);
        setShowAuthOptions(true);
        handleCreateCalendar();
      } else {
        setShowCalendar(false); // Oculta el calendario para otras secciones
        setShowAuthOptions(false);
      }
  
      // Cargar intereses si es necesario
      if (section === 'intereses' && mainInterests.length === 0) {
        fetchInterests();
      } else if (section === 'crearCalendario' || section === 'verEditarCalendario') {
        const questionIndex = sections['Preferencias de Marketing']?.findIndex(q =>
          q.includes('duración') || q.includes('frecuencia')
        );
        setCurrentQuestionIndex(questionIndex !== -1 ? questionIndex : 0);
      }
    } else {
      console.error('Sección no reconocida:', section); // Log para secciones no válidas
    }
  };
  

  
  const fetchInterests = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-interests`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setMainInterests(response.data.main_interests);
      setSubInterests(response.data.sub_interests);
    } catch (error) {
      handleError(error, 'Error al cargar los intereses. Por favor, intenta de nuevo.');
    }
  };


  const renderInputFields = (question, index) => {
    if (!currentSection || !sections[currentSection]) return null;
  
    const isPredefinedQuestion = predefinedOptions[question];
    const currentAnswer = responses[currentSection]?.[question]?.value || '';
  
    if (isPredefinedQuestion) {
      return (
        <div className="options-container">
          {predefinedOptions[question].map((option, optionIndex) => (
            <button
              key={optionIndex}
              className={`option-button ${responses[currentSection] && responses[currentSection][question]?.value === option ? 'selected' : ''}`}
              onClick={() => handleAnswerChange(question, option)}
            >
              {option}
            </button>
          ))}
          <div>
            <label>
              <input 
                type="radio" 
                checked={currentAnswer === 'custom'} 
                onChange={() => handleAnswerChange(question, 'custom')}
              /> Escribe tu propia respuesta
            </label>
            {currentAnswer === 'custom' && (
              <input
                type="text"
                className="input-field custom-option"
                value={customAnswer}
                onChange={handleCustomAnswerChange}
                placeholder="Escribe tu respuesta"
              />
            )}
          </div>
        </div>
      );
    } else if (currentSection === "Test de Personalidad") {
      const options = [
        { letter: 'A', text: 'Muy de acuerdo' },
        { letter: 'B', text: 'De Acuerdo' },
        { letter: 'C', text: 'Algo de Acuerdo' },
        { letter: 'D', text: 'Neutro' },
        { letter: 'E', text: 'No del todo de acuerdo' },
        { letter: 'F', text: 'En desacuerdo' },
        { letter: 'G', text: 'No para nada de acuerdo' }
      ];
  
      return (
        <div className="options-container">
          {options.map((option, optionIndex) => (
            <button
              key={optionIndex}
              className={`option-button ${currentAnswer === option.text ? 'selected' : ''}`}
              onClick={() => handleAnswerChange(question, option.text)}
            >
              <span className="option-letter">{option.letter}</span>
              {option.text}
            </button>
          ))}
        </div>
      );
    } else if (currentSection === "Intereses") {
      return (
        <div className="interests-selection">
          <h3 className="text-xl font-semibold mb-2">Selecciona hasta 3 intereses principales</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
            {mainInterests.map((interest) => (
              <div key={interest} className="flex items-center">
                <input
                  type="checkbox"
                  id={interest}
                  checked={selectedMainInterests.includes(interest)}
                  onChange={() => handleMainInterestChange(interest)}
                  className="mr-2"
                />
                <label htmlFor={interest}>{interest}</label>
              </div>
            ))}
          </div>
          {selectedMainInterests.map((mainInterest) => (
            <div key={mainInterest} className="mb-6">
              <h4 className="text-lg font-semibold mb-2">{mainInterest}</h4>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {subInterests[mainInterest]?.map((subInterest) => (
                  <div key={subInterest} className="flex items-center">
                    <input
                      type="checkbox"
                      id={`${mainInterest}-${subInterest}`}
                      checked={selectedSubInterests[mainInterest]?.includes(subInterest) || false}
                      onChange={() => handleSubInterestChange(mainInterest, subInterest)}
                      className="mr-2"
                    />
                    <label htmlFor={`${mainInterest}-${subInterest}`}>{subInterest}</label>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      );
    } else if (currentSection === "Preferencias de Marketing") {
      switch (question) {
        case "¿De qué temas principales quieres hablar en tus publicaciones?":
          return (
            <textarea
              className="input-field"
              value={currentAnswer}
              onChange={(e) => handleAnswerChange(question, e.target.value)}
              placeholder="Describe los temas principales de tus publicaciones"
              ref={inputRefs.current[currentSection]?.[index]}
            />
          );
          case "¿Qué duración quieres para tu plan de marketing? (semanal/mensual/trimestral)":
            return (
              <select
                className="input-field"
                value={currentAnswer}
                onChange={(e) => handleAnswerChange(question, e.target.value)}
                ref={inputRefs.current[currentSection]?.[index]}
              >
                <option value="">Selecciona una opción</option>
                <option value="semanal">Semanal</option>
                <option value="mensual">Mensual</option>
              </select>
            );
          
            case "¿Con qué frecuencia quieres publicar? (diario/cada 2 días/semanal/etc.)":
              // Obtener la respuesta de la duración seleccionada
              const duracionSeleccionada = responses[currentSection]?.["¿Qué duración quieres para tu plan de marketing? (semanal/mensual/trimestral)"]?.value;
              
              // Definir las opciones basadas en la duración
              const options = duracionSeleccionada === 'mensual' 
                ? ['semanal']
                : ['diario', 'cada 2 días',  'semanal'];
            
              return (
                <select
                  className="input-field"
                  value={currentAnswer}
                  onChange={(e) => handleAnswerChange(question, e.target.value)}
                  ref={inputRefs.current[currentSection]?.[index]}
                >
                  <option value="">Selecciona una opción</option>
                  {options.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              );
            const getFrequencyFromUserPreferences = () => {
              // Asumiendo que las preferencias del usuario están almacenadas en el estado global o en localStorage
              const userPreferences = JSON.parse(localStorage.getItem('userPreferences')) || {};
              return userPreferences.publishFrequency || 'diario'; // 'diario' como valor por defecto
            };
            
            return (
              <div>
                <select
                  className="input-field"
                  value={currentAnswer}
                  onChange={(e) => handleAnswerChange(question, e.target.value)}
                  ref={inputRefs.current[currentSection]?.[index]}
                >
                  <option value="">Selecciona una opción</option>
                  {options.map((option) => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
            );
        default:
          return null;
      }
    } else {
      return (
        <input
          type="text"
          className="input-field"
          value={currentAnswer}
          onChange={(e) => handleAnswerChange(question, e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder={`Ingresa ${question.toLowerCase()}`}
          disabled={index !== currentQuestionIndex}
          ref={inputRefs.current[currentSection]?.[index]}
        />
      );
    }
  };


  
  const renderParsedPosts = () => {
    if (!Array.isArray(parsedPosts) || parsedPosts.length === 0) {

    }
  
    return parsedPosts.map((post, index) => (
      <div key={index} className="bg-gray-800 rounded-lg p-6 mb-6 shadow-lg">
        <div className="flex justify-between items-center mb-4">
          <span className="text-sm text-gray-400">{post.date}</span>
          <span className="text-sm text-blue-500">{post.platform}</span>
        </div>
        <div className="mb-4">
          <h3 className="text-xl font-semibold text-white">{post.title}</h3>
          <p className="text-gray-300 mt-2">{post.content}</p>
          {post.hashtags && (
            <p className="text-sm text-gray-400 mt-2">{post.hashtags}</p>
          )}
        </div>
        <div className="border-t border-gray-700 pt-4">
          <h4 className="text-lg text-white font-semibold mb-2">Detalles de la publicación</h4>
          <ul className="text-gray-400 space-y-1">
            <li><strong>Tipo:</strong> {post.type}</li>
            <li><strong>Objetivo:</strong> {post.objective}</li>
            <li><strong>Descripción de imagen/video:</strong> {post.mediaDescription}</li>
          </ul>
        </div>
      </div>
    ));
  };
  


  useEffect(() => {
    const hasSeenIntro = localStorage.getItem('hasSeenIntro');
  
    // Si el usuario ya ha visto la introducción, no mostrarla
    if (hasSeenIntro) {
      setShowIntro(false);
    }
  }, []);
  
  const renderRawMarketingPlan = () => {
    if (!rawMarketingPlan) {
      return <p className="text-center text-gray-500">No se ha generado ningún plan de marketing aún.</p>;
    }
  
    return (
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
        <pre className="bg-gray-900 text-white p-4 rounded-lg overflow-x-auto">{rawMarketingPlan}</pre>
        <button 
          onClick={handleSavePlan} 
          className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
        >
          Guardar Plan de Marketing
        </button>
      </div>
    );
  };


 
  const renderMainContent = () => {
    if (showHistory) {
      return (
        <ResponseHistory
          responses={responses}
          savedPlans={savedPlans}
          onEditResponses={handleEditResponses}
        />
      );
    }
  
    if (showCalendar) {
      return (
        <>
          {showAuthOptions && (
            <div className="flex flex-col items-center space-y-4 mb-6" />
          )}
          {showAuthOptions && username && (
            <div className="flex flex-col items-center space-y-4 mb-6" />
          )}
          <Calendar
            events={calendarEvents} // Asegurarse de pasar calendarEvents
            initialEvents={parsedPosts}
            onEventSelect={handleEventSelect}  // Agregar esta prop para los nuevos posts
            savedPlans={savedPlans}
            token={token}
            onEventUpdate={handleEventUpdate}
            selectedEvent={selectedEvent}
            isGoogleApiReady={isGoogleApiReady}
            isSignedIn={isLoggedIn}
            userId={username} 
          />
        </>
      );
    }
  
   // Modifica la parte de renderMainContent donde se muestra el plan de marketing
   if (rawMarketingPlan) {
    return (
      <div className="space-y-6">
        {/* Análisis de Personalidad */}
        {responses?.personality_analysis_ai?.analysis && (
          <div className="bg-gray-800/50 backdrop-blur p-6 rounded-xl shadow-lg mb-8">
            <h2 className="text-2xl font-bold text-white mb-4">Tu Perfil de Personalidad</h2>
            <div className="prose prose-invert max-w-none">
              {responses.personality_analysis_ai.analysis.split('\n').map((line, index) => {
                if (line.startsWith('TIPO MBTI:')) {
                  return <h3 key={index} className="text-xl font-semibold text-blue-400 mt-4">{line}</h3>;
                } else if (line.startsWith('FORTALEZAS PRINCIPALES:') || 
                          line.startsWith('ÁREAS DE DESARROLLO:') || 
                          line.startsWith('RECOMENDACIONES')) {
                  return <h4 key={index} className="text-lg font-medium text-purple-400 mt-4">{line}</h4>;
                } else if (line.startsWith('-')) {
                  return <p key={index} className="text-gray-300 ml-4">• {line.substring(1)}</p>;
                } else {
                  return <p key={index} className="text-gray-300">{line}</p>;
                }
              })}
            </div>
          </div>
        )}
  
        {/* Plan de Marketing */}
        <div className="bg-gray-800/50 backdrop-blur p-6 rounded-xl shadow-lg">
          <h2 className="text-3xl font-bold text-white mb-6">Tu Plan de Marketing Personalizado</h2>
          
          {/* Posts Generados */}
          
  
          {/* Botones de Acción */}
          <div className="flex flex-col sm:flex-row justify-center gap-4 mt-8">
           
            <button 
              onClick={handleCreateCalendar}
              className="px-6 py-3 bg-green-600 hover:bg-green-700 text-white font-semibold rounded-lg transition duration-300 flex items-center justify-center"
            >
              <span className="mr-2">📅</span> Crear Calendario
            </button>
          </div>
        </div>
      </div>
    );
  }
  
    return (
      <div className="relative min-h-screen pb-24 md:pb-28">
        <h2 className="text-2xl font-bold mb-8 text-white">{currentSection}</h2>
        <TransitionGroup>
          {sections[currentSection]?.map((question, index) => (
            <CSSTransition
              key={index}
              timeout={500}
              classNames="fade"
            >
              <div 
                ref={questionRefs.current[currentSection]?.[index]}
                className={`mb-6 p-4 md:p-6 bg-gray-800/50 backdrop-blur rounded-xl border border-gray-700/50 
                  ${index === currentQuestionIndex ? 'opacity-100 translate-x-0' : 'opacity-60'} 
                  transition-all duration-300`}
              >
                <div className="flex items-start md:items-center gap-3 mb-4">
                  <div className="flex-shrink-0 w-8 h-8 bg-blue-500/20 text-blue-300 rounded-lg flex items-center justify-center">
                    {index + 1}
                  </div>
                  <div className="text-base md:text-lg text-gray-100">{question}</div>
                </div>
                
                {renderInputFields(question, index)}
                
                {index === currentQuestionIndex && (
                  <div className="flex flex-wrap gap-3 mt-6">
                    {currentQuestionIndex > 0 && (
                      <button 
                        onClick={() => setCurrentQuestionIndex(currentQuestionIndex - 1)}
                        className="px-4 py-2 bg-gray-700/50 hover:bg-gray-600/50 text-gray-200 rounded-lg text-sm md:text-base transition-all duration-200"
                      >
                        Anterior
                      </button>
                    )}
                    <button 
                      onClick={currentQuestionIndex < sections[currentSection].length - 1 
                        ? () => setCurrentQuestionIndex(currentQuestionIndex + 1) 
                        : handleNextSection}
                      className="px-4 py-2 bg-blue-500/20 hover:bg-blue-500/30 text-blue-200 rounded-lg text-sm md:text-base transition-all duration-200"
                    >
                      {currentQuestionIndex < sections[currentSection].length - 1 ? 'Siguiente' : 'Finalizar Sección'}
                    </button>
                  </div>
                )}
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
  
        {/* Fixed position buttons */}
        <div className="fixed bottom-0 left-0 right-0 z-10">
          <div className="bg-gradient-to-t from-gray-900 via-gray-900/95 to-transparent backdrop-blur-sm px-4 py-4 md:py-6">
            <div className="max-w-7xl mx-auto flex flex-col sm:flex-row justify-center items-center gap-3 sm:gap-4">
              <button 
                onClick={handleNextSection}
                className="w-full sm:w-auto px-6 py-2.5 bg-blue-600/90 hover:bg-blue-500/90 text-white text-sm md:text-base 
                  font-medium rounded-lg transition-all duration-200 border border-blue-500/20 shadow-lg 
                  hover:shadow-blue-500/20 backdrop-blur-sm"
              >
                Finalizar Sección
              </button>
              
             
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (!isAuthChecked) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500 border-solid border-gray-200"></div>
        <p className="mt-4 text-lg text-gray-600">Verificando autenticación...</p>
      </div>
    );
  }
  
  if (generatingPlan) {
    return (
      <div>
        <button onClick={() => setGeneratingPlan(true)}></button>
        <MarketingPlanGenerator generatingPlan={generatingPlan} />
      </div>
    );
  } else if (isLoading) {
    return (
      <div>
        <button onClick={() => setIsLoading(true)}></button>
        <MarketingPlanGenerator generatingPlan={isLoading} />
      </div>
    );
  }
  
  // Modifica la sección de renderizado condicional
if (!isLoggedIn) {
  return (
    <div className="auth-container">
      {showPasswordResetRequest ? (
        <PasswordResetRequestForm
          onRequestReset={handleRequestReset}
          error={error}
        />
      ) : showPasswordResetForm ? (
        <PasswordResetForm
          email={passwordResetEmail}
          onResetPassword={handleResetPassword}
          error={error}
        />
      ) : showRegister ? (
        <RegisterForm 
          onRegister={handleRegister}
          onSwitch={() => setShowRegister(false)}
          error={error}
        />
      ) : (
        <LoginForm
          onLogin={handleLogin}
          onSwitch={() => setShowRegister(true)}
          onForgotPassword={handleForgotPassword}
          error={error}
        />
      )}
    </div>
  );
}

return (
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <div className="app-container bg-gradient-to-br from-gray-900 to-black text-white font-roboto min-h-screen flex" ref={containerRef}>
      <AnimatePresence>
        {isLoggedIn && (
          <motion.div
            initial={{ x: -250 }}
            animate={{ x: 0 }}
            exit={{ x: -250 }}
            transition={{ duration: 0.3 }}
          >
            <Sidebar 
              firstName={firstName}
              lastName={lastName}
              onLogout={handleLogout}
              onShowHistory={handleShowHistory}
              onShowPlanCreation={handleShowPlanCreation}
              isHistoryActive={showHistory}
              currentSection={currentSection}
            />
          </motion.div>
        )}
      </AnimatePresence>
      
      <motion.div 
        className="main-content flex-grow p-8 overflow-y-auto"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <AnimatePresence>
          {error ? (
            <motion.div
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -50, opacity: 0 }}
            >
              <ErrorMessage message={error} onClear={() => setError(null)} />
            </motion.div>
          ) : showUserStats && isLoggedIn ? (
            <UserStats />
          ) : showIntro ? (
            <motion.div 
              className="intro-container text-center max-w-4xl mx-auto"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <h1 className="text-5xl font-bold mb-6 bg-gradient-to-r from-blue-400 to-purple-500 text-transparent bg-clip-text">
                Bienvenido a PersonalMARKA
              </h1>
              <p className="text-2xl mb-8 text-gray-300 font-light">
                Descubrí tu verdadero valor y alcanzá otras posibilidades a través de tu marca personal
              </p>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
                <FeatureCard 
                  icon={<Users className="w-12 h-12 text-blue-400" />}
                  title="Equipo Experto"
                  description="Nuestro equipo de marketing y comunicación digital te brinda estrategias personalizadas."
                />
                <FeatureCard 
                  icon={<Target className="w-12 h-12 text-purple-400" />}
                  title="Enfoque Personalizado"
                  description="Nos enfocamos en ti para ayudarte a destacar y lograr nuevos objetivos."
                />
                <FeatureCard 
                  icon={<Star className="w-12 h-12 text-yellow-400" />}
                  title="Diferenciación"
                  description="Te proporcionamos las herramientas necesarias para diferenciarte en tu industria."
                />
              </div>
              <div className="bg-gray-800 p-6 rounded-lg mb-12">
                <h2 className="text-3xl font-semibold mb-4">¿Qué es la marca personal?</h2>
                <p className="text-xl text-gray-300">
                  Es lo que una persona cuenta al mundo, destacando no solo lo que hace, sino cómo y por qué lo hace.
                </p>
              </div>
              <motion.button 
                onClick={() => {
                  if (!isLongPress) {
                    setShowIntro(false);
                  }
                }} 
                onMouseDown={handleLongPressStart}
                onMouseUp={handleLongPressEnd}
                onMouseLeave={handleLongPressEnd}
                onTouchStart={handleLongPressStart}
                onTouchEnd={handleLongPressEnd}
                className="start-button bg-gradient-to-r from-blue-500 to-purple-600 text-white text-xl font-bold py-4 px-10 rounded-full shadow-lg hover:shadow-xl transition duration-300 ease-in-out flex items-center justify-center mx-auto"
                whileHover={{ scale: 1.05, boxShadow: "0 0 15px rgba(74, 144, 226, 0.5)" }}
                whileTap={{ scale: 0.95 }}
              >
                Comenzar tu viaje
                <ChevronRight className="ml-2" size={24} />
              </motion.button>
            </motion.div>
          ) : (
            renderMainContent()
          )}
        </AnimatePresence>
      </motion.div>
    </div>
    <FloatingChat token={token} />
  </GoogleOAuthProvider>
);

};

const FeatureCard = ({ icon, title, description }) => (
  <div className="bg-gray-800 p-6 rounded-lg shadow-md">
    <div className="flex justify-center mb-4">
      {icon}
    </div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-400">{description}</p>
  </div>
);

export default App;