import React, { useState, useEffect, useCallback } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import LinkedInComponent from '../Linkedin/LinkedinInComponent';
import axios from 'axios';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Checkbox,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DeleteIcon from '@mui/icons-material/Delete';
import esLocale from '@fullcalendar/core/locales/es'; // Importa el idioma español
import { Upload } from 'lucide-react';

const StatusBadge = ({ status }) => {
  const getBadgeStyles = () => {
    switch (status) {
      case 'completed':
        return 'bg-green-100 text-green-800 border-green-200';
      case 'failed':
        return 'bg-red-100 text-red-800 border-red-200';
      default:
        return 'bg-blue-100 text-blue-800 border-blue-200';
    }
  };

  return (
    <span
      className={`px-2 py-1 text-xs font-medium rounded-full ${getBadgeStyles()} border`}
    >
      {status === 'completed' ? 'Publicado' : status === 'failed' ? 'Error' : 'Pendiente'}
    </span>
  );
};

const EditDialog = ({ 
  open, 
  onClose,
  tempEvent,
  onEventChange,
  onSubmit,
  onImageUpload,
  selectedImage 
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth={false}
      PaperProps={{
        sx: {
          width: '1000px',
          maxHeight: '85vh',
          margin: '24px'
        }
      }}
    >
      <DialogTitle className="bg-indigo-50/50 px-6 py-3 text-lg font-medium border-b">
        Editar publicación
      </DialogTitle>
      
      <DialogContent className="p-0 flex h-[650px] overflow-hidden">
        {/* Columna izquierda - Formulario */}
        <div className="w-[45%] p-6 space-y-5 overflow-y-auto border-r">
          <div className="space-y-2">
            <label className="text-sm text-gray-600 font-medium">Título</label>
            <TextField
              fullWidth
              size="small"
              placeholder="Escribe el título de tu publicación"
              variant="outlined"
              value={tempEvent?.title || ''}
              onChange={(e) => onEventChange('title', e.target.value)}
            />
          </div>

          <div className="space-y-2">
            <label className="text-sm text-gray-600 font-medium">Contenido</label>
            <TextField
              fullWidth
              multiline
              rows={6}
              placeholder="¿Sobre qué quieres hablar?"
              variant="outlined"
              value={tempEvent?.content || ''}
              onChange={(e) => onEventChange('content', e.target.value)}
            />
          </div>

          <div className="space-y-2">
            <label className="text-sm text-gray-600 font-medium">Hashtags</label>
            <TextField
              fullWidth
              size="small"
              placeholder="#TusHashtags"
              variant="outlined"
              value={tempEvent?.hashtags || ''}
              onChange={(e) => onEventChange('hashtags', e.target.value)}
            />
          </div>

          <div className="space-y-2">
            <label className="text-sm text-gray-600 font-medium">Imagen</label>
            <div className="flex items-center gap-2 mt-1">
              <input
                type="file"
                accept="image/*"
                onChange={onImageUpload}
                className="hidden"
                id="edit-image-upload"
              />
              <label 
                htmlFor="edit-image-upload" 
                className="flex items-center gap-2 px-4 py-2 bg-white border border-gray-300 rounded-md cursor-pointer hover:bg-gray-50"
              >
                <Upload size={18} className="text-gray-600" />
                <span className="text-sm text-gray-600">Subir imagen</span>
              </label>
            </div>
          </div>

          <div className="space-y-2">
            <label className="text-sm text-gray-600 font-medium">Hora de publicación</label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                value={tempEvent?.start || null}
                onChange={(newTime) => onEventChange('start', newTime)}
                slotProps={{
                  textField: {
                    size: 'small',
                    fullWidth: true
                  }
                }}
              />
            </LocalizationProvider>
          </div>
        </div>

        {/* Columna derecha - Preview de LinkedIn */}
        <div className="w-[55%] overflow-y-auto bg-gray-50">
          <div className="p-8">
            <div className="bg-white rounded-lg shadow-sm">
              {/* Header con foto y nombre */}
              <div className="p-4 flex items-center gap-3">
                <div className="w-12 h-12 bg-gray-200 rounded-full flex-shrink-0" />
                <div>
                  <div className="font-semibold text-[15px]">Tu Nombre</div>
                  <div className="text-xs text-gray-500">
                    {tempEvent?.start ? new Date(tempEvent.start).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit'
                    }) : ''} • 🌐
                  </div>
                </div>
              </div>

              {/* Contenido del post */}
              <div className="px-4 pb-3 text-[14px] whitespace-pre-wrap">
                {tempEvent?.content || 'Tu contenido aparecerá aquí'}
              </div>

              {/* Hashtags */}
              {tempEvent?.hashtags && (
                <div className="px-4 pb-3 text-[13px] text-indigo-600">
                  {tempEvent.hashtags}
                </div>
              )}

              {/* Imagen */}
              {(tempEvent?.image || selectedImage) && (
                <div className="relative">
                  <img
                    src={tempEvent?.image || selectedImage}
                    alt="Preview"
                    className="w-full max-h-[350px] object-cover"
                  />
                  <button
                    onClick={() => onEventChange('image', null)}
                    className="absolute top-3 right-3 bg-red-500 text-white p-1.5 rounded-full hover:bg-red-600 transition-colors"
                  >
                    <DeleteIcon className="w-4 h-4" />
                  </button>
                </div>
              )}

              {/* Botones de interacción */}
              <div className="px-4 py-3 flex justify-between border-t mt-2">
                <button className="text-gray-600 text-sm hover:bg-gray-50 px-3 py-1.5 rounded-md transition-colors">
                  Like
                </button>
                <button className="text-gray-600 text-sm hover:bg-gray-50 px-3 py-1.5 rounded-md transition-colors">
                  Comment
                </button>
                <button className="text-gray-600 text-sm hover:bg-gray-50 px-3 py-1.5 rounded-md transition-colors">
                  Share
                </button>
                <button className="text-gray-600 text-sm hover:bg-gray-50 px-3 py-1.5 rounded-md transition-colors">
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>

      <DialogActions className="p-4 border-t bg-white">
        <Button
          onClick={onClose}
          className="text-gray-600 hover:bg-gray-50 text-sm px-6 py-2 rounded-md"
        >
          Cancelar
        </Button>
        <Button
          onClick={onSubmit}
          className="bg-indigo-600 text-blue hover:bg-indigo-700 text-sm px-6 py-2 rounded-md"
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};


const Calendar = ({
  events: initialEvents,
  savedPlans,
  token,
  onEventUpdate,
  selectedEvent,
  isGoogleApiReady,
  isSignedIn,
  userId,
}) => {
  // Estados
  const [events, setEvents] = useState(initialEvents);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isNewEventDialogOpen, setIsNewEventDialogOpen] = useState(false);
  const [isEditEventDialogOpen, setIsEditEventDialogOpen] = useState(false);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [newEventDate, setNewEventDate] = useState(null);
  const [calendarKey, setCalendarKey] = useState(0); // Estado de la clave del calendario
  const [editingEvent, setEditingEvent] = useState(null);
  const [tempEditingEvent, setTempEditingEvent] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [scheduledPosts, setScheduledPosts] = useState([]);
  const [newEvent, setNewEvent] = useState({
    title: '',
    content: '',
    time: new Date(),
    image: null,
    hashtags: '',
  });

  useEffect(() => {
    handleSaveAllChanges(); // Llama la función para guardar los cambios al cargar el calendario
  }, []);

  useEffect(() => {
    if (!Array.isArray(initialEvents)) {
      console.error('Los eventos recibidos no son un array:', initialEvents);
      return;
    }

    setEvents((prevEvents) => {
      const uniqueEventsMap = new Map();
      const combinedEvents = [...prevEvents, ...initialEvents];

      combinedEvents.forEach((event) => {
        uniqueEventsMap.set(event.id, event); // Evita duplicados usando el ID del evento
      });

      const updatedEvents = Array.from(uniqueEventsMap.values());

      // Validar y filtrar los eventos
      const frequency = getFrequencyFromUserPreferences();
      const filtered = filterEventsByFrequency(
        validateEvents(updatedEvents),
        frequency
      );
      setFilteredEvents(filtered);

      return updatedEvents;
    });

    fetchScheduledPosts();
  }, [initialEvents, userId]);

  // Funciones auxiliares
  const getFrequencyFromUserPreferences = () => {
    const userPreferences = JSON.parse(localStorage.getItem('userPreferences')) || {};
    return userPreferences.publishFrequency || 'diario';
  };

  const validateEvents = (eventsData) => {
    if (!Array.isArray(eventsData)) {
      console.error('Los datos de eventos no son un array válido');
      return [];
    }
    return eventsData
      .map((event) => ({
        ...event,
        start: event.start ? new Date(event.start) : null,
        end: event.end ? new Date(event.end) : null,
      }))
      .filter((event) => event.start && !isNaN(event.start.getTime()));
  };

  const filterEventsByFrequency = (events, frequency) => {
    const sortedEvents = events.sort(
      (a, b) => new Date(a.start) - new Date(b.start)
    );
    switch (frequency) {
      case 'diario':
        return sortedEvents;
      case 'cada 2 días':
        return sortedEvents.filter((_, index) => index % 2 === 0);
      case 'cada 3 días':
        return sortedEvents.filter((_, index) => index % 3 === 0);
      case 'semanal':
        return sortedEvents.filter((_, index) => index % 7 === 0);
      default:
        return sortedEvents;
    }
  };

  // Función auxiliar para determinar el color del evento basado en su estado
  const getEventColor = (event) => {
    // Buscar si el evento está en la lista de posts programados
    const scheduledPost = scheduledPosts.find(
      (post) => post.id.toString() === event.id.toString()
    );

    if (scheduledPost) {
      // Si el post está programado, usar su estado para determinar el color
      switch (scheduledPost.status) {
        case 'completed':
          return '#22c55e'; // verde para posts publicados
        case 'failed':
          return '#ef4444'; // rojo para posts fallidos
        case 'pending':
          return '#3b82f6'; // azul para posts pendientes
        default:
          return '#3b82f6'; // azul por defecto para posts programados
      }
    }

    // Color para eventos no programados
    return '#6366f1'; // violeta/indigo para posts no programados
  };

  // Agregar el manejador de actualización de eventos
  const handleEventUpdate = async (updatedEvent) => {
    try {
      const eventsToUpdate = Array.isArray(updatedEvent)
        ? updatedEvent
        : [updatedEvent];

      // Crear un mapa de IDs para evitar duplicados
      const eventMap = new Map();

      // Formatear los eventos para el backend y eliminar duplicados
      const formattedEvents = eventsToUpdate
        .map((event) => {
          const eventId = event.id;

          // Si ya existe un evento con este ID, no lo incluimos
          if (eventMap.has(eventId)) {
            return null;
          }

          const formatted = {
            id: eventId,
            title: event.title,
            start:
              event.start instanceof Date
                ? event.start.toISOString()
                : new Date(event.start).toISOString(),
            end:
              event.end instanceof Date
                ? event.end.toISOString()
                : new Date(event.end).toISOString(),
            content: event.extendedProps?.content || '',
            hashtags: event.extendedProps?.hashtags || '',
            platform: event.extendedProps?.platform || 'LinkedIn',
            image: event.extendedProps?.image || null,
          };

          eventMap.set(eventId, formatted);
          return formatted;
        })
        .filter((event) => event !== null);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/save-calendar`,
        { events: formattedEvents },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        // Actualizar el estado local sin duplicados
        setEvents((prevEvents) => {
          const newEventsMap = new Map();

          // Primero agregamos los eventos existentes que no se están actualizando
          prevEvents.forEach((event) => {
            const eventId = event.id;
            if (!eventMap.has(eventId)) {
              newEventsMap.set(eventId, event);
            }
          });

          // Luego agregamos los eventos actualizados
          formattedEvents.forEach((event) => {
            newEventsMap.set(event.id, {
              ...event,
              start: new Date(event.start),
              end: new Date(event.end),
              extendedProps: {
                content: event.content,
                hashtags: event.hashtags,
                platform: event.platform,
                image: event.image,
              },
            });
          });

          return Array.from(newEventsMap.values());
        });

        return true;
      }

      throw new Error('Error al actualizar los eventos');
    } catch (error) {
      console.error('Error en handleEventUpdate:', error);
   
      return false;
    }
  };

  // Nueva función para la publicación inmediata
  const handleImmediatePublish = async () => {
    const currentTime = new Date();
    currentTime.setSeconds(currentTime.getSeconds() + 30); // Añade 30 segundos a la hora actual

    const linkedinToken = localStorage.getItem('linkedinToken');
    const immediatePost = selectedEvents.map((event) => ({
      content: event.extendedProps?.content,
      scheduledAt: currentTime.toISOString(),
      image: event.extendedProps?.image,
      hashtags: event.extendedProps?.hashtags,
    }));

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_LINKEDIN_API_URL}/api/linkedin/batch-schedule`,
        { posts: immediatePost, user_id: userId },
        {
          headers: { Authorization: `Bearer ${linkedinToken}`, 'Content-Type': 'application/json' },
          timeout: 30000,
        }
      );

      if (response.status === 200) {
        alert('Publicación inmediata realizada con éxito.');
        setSelectedEvents([]);
        fetchScheduledPosts();
      } else {
        setError('Error al realizar la publicación inmediata');
      }
    } catch (error) {
      console.error('Error en la publicación inmediata:', error);
      setError('Error al realizar la publicación inmediata. Inténtalo nuevamente.');
    }
  };

  // Manejadores de eventos
  const handleEventClick = (clickInfo) => {
    const event = clickInfo.event;
    const eventData = {
      id: event.id,
      title: event.title,
      content: event.extendedProps.content,
      hashtags: event.extendedProps.hashtags,
      start: event.start,
      end: event.end,
      image: event.extendedProps.image || null,
    };
    setEditingEvent(eventData);
    setTempEditingEvent(eventData);
    setIsEditEventDialogOpen(true);
  };

  const handleDateClick = (arg) => {
    setNewEventDate(arg.date);
    setNewEvent({
      title: '',
      content: '',
      time: arg.date,
      image: null,
      hashtags: '',
    });
    setIsNewEventDialogOpen(true);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (file.size > 5 * 1024 * 1024) {
          setError('La imagen es demasiado grande. Por favor, selecciona una imagen menor a 5MB.');
          return;
        }
        const imageData = reader.result;
        setSelectedImage(imageData);
        if (isEditEventDialogOpen) {
          setTempEditingEvent((prev) => ({
            ...prev,
            image: imageData,
          }));
        } else {
          setNewEvent((prev) => ({
            ...prev,
            image: imageData,
          }));
        }
      };
      reader.readAsDataURL(file);
    }
  };

  // Función auxiliar para verificar si un evento está en el pasado
  const isEventInPast = (eventDate) => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const date = new Date(eventDate);
    return date < now;
  };

  // Función auxiliar para verificar si el evento se está moviendo al mismo mes
  const isSameMonth = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    return (
      startDate.getMonth() === endDate.getMonth() &&
      startDate.getFullYear() === endDate.getFullYear()
    );
  };

  // Modificar el manejador de eventDrop
  const handleEventDrop = async (eventDropInfo) => {
    const { event, oldEvent } = eventDropInfo;

    // Verificar si el evento se está moviendo al mismo mes
    if (!isSameMonth(oldEvent.start, event.start)) {
      eventDropInfo.revert();
      alert('Los eventos solo pueden moverse dentro del mismo mes.');
      return;
    }

    const updatedEvent = {
      ...event.toPlainObject(),
      start: event.start,
      end: event.end,
    };
    await handleEventUpdate(updatedEvent);
  };

  const handleEventResize = async (eventResizeInfo) => {
    const updatedEvent = {
      ...eventResizeInfo.event.toPlainObject(),
      start: eventResizeInfo.event.start,
      end: eventResizeInfo.event.end,
    };
    await handleEventUpdate(updatedEvent);
  };

  const handleNewEventChange = (field, value) => {
    setNewEvent((prev) => ({ ...prev, [field]: value }));
  };

  const handleNewEventSubmit = () => {
    const eventDateTime = new Date(newEventDate);
    const timeDate = new Date(newEvent.time);

    // Transferir la hora y minutos manteniendo la fecha correcta
    eventDateTime.setHours(timeDate.getHours());
    eventDateTime.setMinutes(timeDate.getMinutes());
    eventDateTime.setSeconds(0);
    eventDateTime.setMilliseconds(0);

    const newEventObject = {
      id: Date.now().toString(),
      title: newEvent.title,
      start: eventDateTime,
      end: new Date(eventDateTime.getTime() + 60 * 60 * 1000),
      extendedProps: {
        content: newEvent.content,
        hashtags: newEvent.hashtags,
        platform: 'LinkedIn',
        image: newEvent.image,
      },
    };

    setEvents((prevEvents) => [...prevEvents, newEventObject]);
    setIsNewEventDialogOpen(false);
    setNewEvent({
      title: '',
      content: '',
      time: new Date(),
      image: null,
      hashtags: '',
    });
    setSelectedImage(null);

    // Guardar el nuevo evento en el backend
    handleEventUpdate(newEventObject);
  };

  const handleEditEventChange = (field, value) => {
    setTempEditingEvent((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleEditEventClose = () => {
    setIsEditEventDialogOpen(false);
    setTempEditingEvent(null);
    setSelectedImage(null);
  };

  const handleEditEventSubmit = async () => {
    if (!tempEditingEvent) return;

    const formattedEvent = {
      id: tempEditingEvent.id,
      title: tempEditingEvent.title,
      start: tempEditingEvent.start,
      end: tempEditingEvent.end,
      extendedProps: {
        content: tempEditingEvent.content,
        hashtags: tempEditingEvent.hashtags,
        platform: 'LinkedIn',
        image: tempEditingEvent.image,
      },
    };

    // Actualizar el evento en el estado local
    setEvents((prevEvents) => {
      return prevEvents.map((event) =>
        event.id === formattedEvent.id ? formattedEvent : event
      );
    });

    setEditingEvent(formattedEvent);
    setIsEditEventDialogOpen(false);

    // Llamar a handleEventUpdate para guardar los cambios en el backend
    const success = await handleEventUpdate(formattedEvent);
    if (success) {
      alert('Evento actualizado y guardado exitosamente.');
    } else {
      alert('Error al guardar el evento. Por favor, inténtalo de nuevo.');
    }
  };

  const handleSaveAllChanges = async () => {
    try {
      const updatedEvents = events.map((event) => {
        return {
          id: event.id,
          title: event.title,
          start:
            event.start instanceof Date
              ? event.start.toISOString()
              : new Date(event.start).toISOString(),
          end:
            event.end instanceof Date
              ? event.end.toISOString()
              : new Date(event.end).toISOString(),
          content:
            event.extendedProps?.content ||
            event.content ||
            '',
          hashtags:
            event.extendedProps?.hashtags ||
            event.hashtags ||
            '',
          platform:
            event.extendedProps?.platform ||
            event.platform ||
            'LinkedIn',
          image:
            event.extendedProps?.image ||
            event.image ||
            null,
        };
      });

      // Enviar al backend
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/save-calendar`,
        { events: updatedEvents },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        // Actualizar el estado local con los eventos actualizados
        const formattedEvents = updatedEvents.map((event) => ({
          ...event,
          start: new Date(event.start),
          end: new Date(event.end),
          extendedProps: {
            content: event.content,
            hashtags: event.hashtags,
            platform: event.platform,
            image: event.image,
          },
        }));

        setEvents(formattedEvents);
        setCalendarEvents(formattedEvents);
      } else {
        throw new Error('Error al guardar el calendario');
      }
    } catch (error) {
      console.error('Error al guardar los cambios:', error);

    }
  };

  const handleEventSelection = (event) => {
    setSelectedEvents((prevSelected) => {
      const isAlreadySelected = prevSelected.some((e) => e.id === event.id);
      if (isAlreadySelected) {
        return prevSelected.filter((e) => e.id !== event.id);
      } else {
        return [...prevSelected, event];
      }
    });
  };

  const handleBatchLinkedInPublish = async () => {
    if (selectedEvents.length === 0) {
      setError('No hay eventos seleccionados para publicar');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const linkedinToken = localStorage.getItem('linkedinToken');
      const postsToSchedule = selectedEvents.map((event) => ({
        id: event.id,
        content: event.extendedProps?.content,
        scheduledAt:
          event.start instanceof Date ? event.start.toISOString() : event.start,
        image: event.extendedProps?.image,
        hashtags: event.extendedProps?.hashtags,
      }));

      const response = await axios.post(
        `${process.env.REACT_APP_LINKEDIN_API_URL}/api/linkedin/batch-schedule`,
        {
          posts: postsToSchedule,
          user_id: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${linkedinToken}`,
            'Content-Type': 'application/json',
          },
          timeout: 30000,
        }
      );

      if (response.status === 200 && response.data.scheduled_posts) {
        setEvents((prevEvents) => {
          return prevEvents.map((event) => {
            const matchingPost = response.data.scheduled_posts.find(
              (post) => post.id === event.id
            );

            if (matchingPost) {
              return {
                ...event,
                extendedProps: {
                  ...event.extendedProps,
                  status: 'pending',
                  scheduledAt: matchingPost.scheduledAt,
                },
              };
            }
            return event;
          });
        });

        // Actualiza scheduledPosts
        const newScheduledPosts = response.data.scheduled_posts.map((post) => ({
          ...post,
          status: 'pending',
        }));
        setScheduledPosts((prevPosts) => [...prevPosts, ...newScheduledPosts]);

        alert(`${response.data.scheduled_posts.length} publicaciones programadas exitosamente`);
        setSelectedEvents([]);

        // Forzar la recarga del calendario
        setCalendarKey((prevKey) => prevKey + 1);
        await fetchScheduledPosts();
      }
    } catch (error) {
      console.error('Error al programar posts:', error);
      setError('Error al programar en LinkedIn: ' + (error.response?.data?.error || error.message));
    } finally {
      setIsLoading(false);
    }
  };

  // Actualizar fetchScheduledPosts para mantener los estados correctamente
  const fetchScheduledPosts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LINKEDIN_API_URL}/api/linkedin/scheduled-posts`,
        {
          params: { user_id: userId },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('linkedinToken')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data && Array.isArray(response.data)) {
        setScheduledPosts(response.data);

        setEvents((prevEvents) => {
          return prevEvents.map((event) => {
            const scheduledPost = response.data.find(
              (post) => post.id === event.id
            );
            if (scheduledPost) {
              return {
                ...event,
                extendedProps: {
                  ...event.extendedProps,
                  status: scheduledPost.status,
                  scheduledAt: scheduledPost.scheduledAt,
                },
              };
            }
            return event;
          });
        });

        // Fuerza la actualización del calendario después de actualizar los posts
        setCalendarKey((prevKey) => prevKey + 1);
      }
    } catch (error) {
      console.error('Error al obtener posts programados:', error);
    }
  };

  const handleCancelPost = async (postId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_LINKEDIN_API_URL}/api/linkedin/cancel-post/${postId}`,
        {
          params: { user_id: userId },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('linkedinToken')}`,
          },
        }
      );

      // Actualizar los estados locales después de cancelar el post
      setScheduledPosts((prevPosts) =>
        prevPosts.filter((post) => post.id.toString() !== postId.toString())
      );

      // Actualizar el estado del evento
      setEvents((prevEvents) => {
        return prevEvents.map((event) => {
          if (event.id.toString() === postId.toString()) {
            return {
              ...event,
              extendedProps: {
                ...event.extendedProps,
                status: undefined, // Remover el estado del post cancelado
                scheduledAt: undefined,
              },
            };
          }
          return event;
        });
      });

      alert('Post cancelado exitosamente');

      // Actualizar la lista completa de posts programados
      await fetchScheduledPosts();
    } catch (error) {
      console.error('Error canceling post:', error);
      setError('Error al cancelar el post');
    }
  };

  // Renderizado de evento
  const renderEventContent = (eventInfo) => {
    const { event } = eventInfo;
    const isSelected = selectedEvents.some((e) => e.id === event.id);
    const scheduledPost = scheduledPosts.find(
      (post) => post.id.toString() === event.id.toString()
    );
    const hasImage = event.extendedProps?.image;
    const isPast = isEventInPast(event.start);

    let status = 'pending';
    if (scheduledPost) {
      status = scheduledPost.status || 'pending';
    }

    return (
      <div
        className={`p-1 rounded w-full transition-all duration-200 shadow-sm overflow-hidden
                        ${isSelected ? 'bg-opacity-90' : 'bg-opacity-75'}
                        ${isPast ? 'opacity-40' : ''}`}
        style={{ backgroundColor: getEventColor(event) }}
      >
        <div className="flex items-center justify-between">
          <Checkbox
            checked={isSelected}
            onChange={() => handleEventSelection(event)}
            onClick={(e) => e.stopPropagation()}
            className="text-white"
            disabled={isPast}
          />
          {scheduledPost && <StatusBadge status={status} />}
        </div>

        <div className="text-xs font-semibold mb-1 truncate text-white">
          {event.title}
        </div>

        <div className="text-xxs text-white/80 truncate">
          {event.extendedProps.platform || 'LinkedIn'}
          {scheduledPost && (
            <span className="ml-2">
              {new Date(scheduledPost.scheduledAt).toLocaleTimeString('es-ES', {
                hour: '2-digit',
                minute: '2-digit',
              })}
            </span>
          )}
        </div>

        {hasImage && (
          <div className="w-full h-20 relative overflow-hidden rounded mt-1">
            <img
              src={event.extendedProps.image}
              alt="Post content"
              className="w-full h-full object-cover"
            />
          </div>
        )}

        {scheduledPost && !isPast && (
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              handleCancelPost(event.id);
            }}
            className="mt-1 text-white hover:text-red-500"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        )}
      </div>
    );
  };

  return (
    <div className="container mx-auto px-4 bg-gradient-to-br from-indigo-100 text-black to-purple-100 rounded-xl shadow-2xl p-8 my-10">
      <LinkedInComponent userId={userId} />

      {/* Botones de acción principales */}
      <div className="mb-6 flex flex-wrap justify-between items-center gap-4">
        <div className="flex space-x-2">
          <button
            onClick={() => setSelectedEvents(filteredEvents)}
            className="bg-indigo-600 text-white  px-4 py-2 rounded-l hover:bg-indigo-700 transition-colors duration-200 shadow-md text-sm"
          >
            Seleccionar Todos
          </button>
          <button
            onClick={() => setSelectedEvents([])}
            className="bg-gray-600 text-white px-4 py-2 rounded-r hover:bg-gray-700 transition-colors duration-200 shadow-md text-sm"
          >
            Deseleccionar Todos
          </button>
        </div>

        <button
          onClick={handleBatchLinkedInPublish}
          disabled={isLoading || selectedEvents.length === 0}
          className="bg-green-600 text-white px-6 py-2 rounded-full hover:bg-green-700 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200 shadow-md transform hover:scale-105 text-sm"
        >
          {isLoading
            ? 'Programando...'
            : `Programar ${selectedEvents.length} eventos en LinkedIn`}
        </button>
        <button
          onClick={handleImmediatePublish}
          disabled={isLoading || selectedEvents.length === 0}
          className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200 shadow-md transform hover:scale-105 text-sm"
        >
          {isLoading
            ? 'Publicando...'
            : `Publicar ${selectedEvents.length} evento(s) inmediatamente`}
        </button>
      </div>

      {/* Mensaje de error */}
      {error && (
        <div className="mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
          <span className="block sm:inline">{error}</span>
        </div>
      )}

      {/* Calendario */}
      <div className="mb-8 bg-white rounded-xl shadow-lg overflow-hidden">
        <FullCalendar
          key={calendarKey} // Actualiza FullCalendar al cambiar la clave
          plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
          initialView="dayGridMonth"
          events={events}
          editable={true}
          locale={esLocale}
          selectable={true}
          eventClick={handleEventClick}
          eventDrop={handleEventDrop}
          eventResize={handleEventResize}
          eventContent={renderEventContent}
          dateClick={handleDateClick}
          height="auto"
          aspectRatio={1.5}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,dayGridWeek,dayGridDay,listMonth',
          }}
          eventBackgroundColor="transparent"
          eventBorderColor="transparent"
          dayHeaderClassNames="bg-indigo-100 text-indigo-800 uppercase text-sm font-semibold py-2"
          dayCellClassNames="hover:bg-indigo-50 transition-colors duration-200 p-1"
          eventConstraint={{
            startTime: '00:00',
            endTime: '24:00',
          }}
        />
      </div>

      {/* Botón de guardar */}
      

      {/* Lista de posts programados */}
      <div className="mt-8 bg-white rounded-xl shadow-lg p-6">
        <h2 className="text-2xl font-bold mb-4 text-indigo-800">Posts Programados</h2>
        {scheduledPosts.length > 0 ? (
          <List>
            {scheduledPosts.map((post) => (
              <ListItem
                key={post.id}
                className="border-b last:border-b-0 flex justify-between items-center py-4"
              >
                <div className="flex-grow mr-4">
                  <ListItemText
                    primary={
                      <span className="font-semibold text-indigo-700">
                        {post.content.substring(0, 50)}
                        {post.content.length > 50 ? '...' : ''}
                      </span>
                    }
                    secondary={
                      <React.Fragment>
                        <span className="block text-sm text-gray-600">
                          Fecha: {new Date(post.scheduledAt).toLocaleDateString()}
                        </span>
                        <span className="block text-sm text-gray-600">
                          Hora: {new Date(post.scheduledAt).toLocaleTimeString()}
                        </span>
                      </React.Fragment>
                    }
                  />
                </div>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => handleCancelPost(post.id)}
                  className="bg-red-500 hover:bg-red-600 text-white"
                >
                  Desprogramar
                </Button>
              </ListItem>
            ))}
          </List>
        ) : (
          <p className="text-gray-600">No hay posts programados.</p>
        )}
      </div>

      {/* Modal de nuevo evento */}
      <Dialog
        open={isNewEventDialogOpen}
        onClose={() => setIsNewEventDialogOpen(false)}
        PaperProps={{
          className: 'rounded-xl shadow-xl',
        }}
      >
        <DialogTitle className="bg-indigo-100 text-indigo-800">
          Crear nueva publicación
        </DialogTitle>
        <DialogContent className="mt-4">
          <div className="grid grid-cols-2 gap-6">
            {/* Columna del formulario */}
            <div className="space-y-4">
              <TextField
                autoFocus
                margin="dense"
                label="Título del evento"
                type="text"
                fullWidth
                variant="outlined"
                value={newEvent.title}
                onChange={(e) => handleNewEventChange('title', e.target.value)}
              />
              <TextField
                margin="dense"
                label="Contenido del post"
                type="text"
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                value={newEvent.content}
                onChange={(e) => handleNewEventChange('content', e.target.value)}
              />
              <TextField
                margin="dense"
                label="Hashtags"
                type="text"
                fullWidth
                variant="outlined"
                value={newEvent.hashtags}
                onChange={(e) => handleNewEventChange('hashtags', e.target.value)}
              />
              <div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  className="hidden"
                  id="new-image-upload"
                />
                <label htmlFor="new-image-upload" className="cursor-pointer">
                  <div className="flex items-center gap-2 text-indigo-600 hover:text-indigo-700">
                    <Upload size={20} />
                    <span>Subir imagen</span>
                  </div>
                </label>
              </div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  label="Hora del evento"
                  value={newEvent.time}
                  onChange={(newTime) => handleNewEventChange('time', newTime)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      margin: 'dense',
                      variant: 'outlined',
                    },
                  }}
                />
              </LocalizationProvider>
            </div>

            {/* Columna de preview de LinkedIn */}
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              <div className="p-4">
                {/* Header del post */}
                <div className="flex items-center space-x-3">
                  <div className="w-12 h-12 bg-gray-200 rounded-full"></div>
                  <div>
                    <div className="font-semibold text-gray-900">Tu Nombre</div>
                    <div className="text-sm text-gray-500">
                      {new Date().toLocaleTimeString()} • 🌐
                    </div>
                  </div>
                </div>

                {/* Contenido del post */}
                <div className="mt-4 text-gray-800 whitespace-pre-wrap">
                  {newEvent.content}
                </div>

                {/* Hashtags */}
                {newEvent.hashtags && (
                  <div className="mt-2 text-indigo-600">
                    {newEvent.hashtags}
                  </div>
                )}

                {/* Preview de imagen */}
                {newEvent.image && (
                  <div className="mt-4 relative">
                    <img
                      src={newEvent.image}
                      alt="Preview"
                      className="w-full h-48 object-cover rounded"
                    />
                    <button
                      onClick={() => handleNewEventChange('image', null)}
                      className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full"
                    >
                      <DeleteIcon fontSize="small" />
                    </button>
                  </div>
                )}

                {/* Botones de interacción */}
                <div className="mt-4 flex justify-between border-t pt-4">
                  <button className="text-gray-600">Like</button>
                  <button className="text-gray-600">Comment</button>
                  <button className="text-gray-600">Share</button>
                  <button className="text-gray-600">Send</button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="p-4">
          <Button
            onClick={() => setIsNewEventDialogOpen(false)}
            className="text-gray-600 hover:bg-gray-100"
          >
            Cancelar
          </Button>
          <Button
            onClick={handleNewEventSubmit}
            className="bg-indigo-600 text-white hover:bg-indigo-700"
          >
            Crear
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal de edición de evento con preview de LinkedIn */}
      <EditDialog
        open={isEditEventDialogOpen}
        onClose={handleEditEventClose}
        tempEvent={tempEditingEvent}
        onEventChange={handleEditEventChange}
        onSubmit={handleEditEventSubmit}
        onImageUpload={handleImageUpload}
        selectedImage={selectedImage}
      />

    </div>
  );
};

export default Calendar;