// LoginForm.jsx
import React, { useState } from 'react';

const LoginForm = ({ onLogin, onSwitch, onForgotPassword, error }) => {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onLogin(usernameOrEmail, password);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-cover bg-center" style={{backgroundImage: "url('https://personalmarka.com/wp-content/uploads/2023/09/social-media-consultant-27-4.jpg')"}}>
      <div className="bg-gray-800 bg-opacity-80 p-8 rounded-lg shadow-xl w-96 border border-gray-700">
        <h2 className="text-3xl font-bold mb-6 text-white">Iniciar Sesión</h2>
        {error && <p className="text-red-400 mb-4">{error}</p>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="usernameOrEmail" className="block text-sm font-medium text-gray-300">Correo:</label>
            <input
              type="text"
              id="usernameOrEmail"
              value={usernameOrEmail}
              onChange={(e) => setUsernameOrEmail(e.target.value)}
              required
              className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm text-white placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500"
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-300">Contraseña:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm text-white placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500"
            />
          </div>
          <button type="submit" className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-green-500">
            Iniciar Sesión
          </button>
        </form>
        <div className="mt-4 flex justify-between">
          <button 
            onClick={onForgotPassword}
            className="text-sm text-green-400 hover:text-green-300 focus:outline-none"
            type="button"
          >
            ¿Olvidaste tu contraseña?
          </button>
          <button 
            onClick={onSwitch} 
            className="text-sm text-green-400 hover:text-green-300 focus:outline-none"
            type="button"
          >
            Regístrate
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;