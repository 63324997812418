import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';

const ErrorMessage = ({ message, onClear }) => {
  // Solo mostrar el error si el mensaje no está relacionado con la falta de planes
  if (!message || message.includes('No hay planes guardados')) return null;

  return (
    <div className="error-container">
      <FaExclamationTriangle className="error-icon" />
      <h2>Oops! Algo salió mal</h2>
      <p>{message}</p>
      <button onClick={onClear} className="error-button">Intentar de nuevo</button>
    </div>
  );
};

export default ErrorMessage;